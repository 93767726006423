// Importing necessary libraries
import { useEffect } from "react"

export default function Cb(props) {
    // Using useEffect to run the script after the component mounts
    useEffect(() => {
        const script = document.createElement("script")
        script.onload = function () {
            window.voiceflow.chat.load({
                verify: { projectID: "64fde8a9ca96620008ca84fa" },
                url: "https://general-runtime.voiceflow.com",
                versionID: "production",
            })
        }
        script.src = "https://cdn.voiceflow.com/widget/bundle.mjs"
        script.type = "text/javascript"
        document.head.appendChild(script)

        // Cleanup function to remove the script when the component unmounts
        return () => {
            document.head.removeChild(script)
        }
    }, [])

    // Returning the component with inline styles
    return (
        <div style={containerStyle}>
            <div id="voiceflow-chat" style={launcherStyle}></div>
        </div>
    )
}

// Defining the styles as JavaScript objects
const containerStyle = {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
}

const launcherStyle = {
    position: "relative",
    animation: "pulse 2s infinite",
    // Add any other styles you want to apply to the launcher here
}

// Adding the styles and keyframes to the document head
const style = document.createElement("style")
style.textContent = `
    @keyframes pulse {
        0% {
            transform: scale(1);
            opacity: 1;
        }
        50% {
            transform: scale(1.1);
            opacity: 0.7;
        }
        100% {
            transform: scale(1);
            opacity: 1;
        }
    }

    #voiceflow-chat .vfrc-launcher:after {
        content: 'Demo me!';
        position: absolute;
        bottom: calc(100% + 20px);
        left: 50%;
        transform: translateX(-50%);
        background-color: #000;
        color: #fff;
        padding: 10px;
        border-radius: 5px;
        white-space: nowrap;
        opacity: 1;
        pointer-events: none;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        font-family: 'Comic Sans MS', 'Chalkboard SE', 'Comic Neue', sans-serif;
        font-size: 16px;
        font-weight: bold;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    }

    #voiceflow-chat .vfrc-launcher:before {
        content: '';
        position: absolute;
        bottom: calc(100% + 10px);
        left: 50%;
        transform: translateX(-50%) translateY(50%);
        border-width: 10px;
        border-style: solid;
        border-color: #000 transparent transparent transparent;
    }
`
document.head.appendChild(style)
